/*Fonts import*/
@font-face {
  font-family: 'Circe_MD_b';
  src:  url('../fonts/CRC75PMD.woff2') format('woff2');
}
@font-face {
  font-family: 'Circe_MD';
  src:  url('../fonts/CRC65PMD.woff2') format('woff2');
}
@font-face {
  font-family: 'PT_Sans';
  src:  url('../fonts/pt-sans.ttf');
}

/*Main styles*/
h1, h2, h3 {
  font-family: 'Circe_MD_b', Fallback, sans-serif;
  color: $dark-brown;
  span{
    display: block;
    color: $beige;
  }
}
body{
  .row{
    margin-left: 0;
    margin-right: 0;
  }
}
.reg_btn{
    background-color: $red;
    border-color: $red;
}
.header-info{
  margin-top:10px;
  .reg_btn{
    min-width: 169px;
  }
  .wsag_btn{
    min-width: 245px;
  }
}
.wsag_btn .glass{
  width: 27px;
  height: 20px;
  display: inline-block;
  background-image: url("../img/glass.svg");
  background-repeat: no-repeat;
  margin-right: 5px;
  margin-bottom: -4px;
}

/* Header*/
.right-header{
  .main_im{
    max-height: 280px;
  }
}
/* Serch form */
.search-input{
  max-width: 645px;
  border-color: $beige;
}
.search-btn{
  margin-left: 20px;
  width: 135px;
}
a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus{
  color: $white;
}
/*Footer*/
.footer{
  background-color: $dark-brown;
  p,h2,h3,h4,a,li{
    color: white;
  }
  .footer_adress{
     color: $beige;
  }
  .copyright{
    p,a{
      color: $beige;
    }
  }
}
